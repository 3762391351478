import $ from 'jquery';

$(function(){

  $('.modal').modaal({
    content_source: '#agreement-terms-of-service',
    overlay_opacity: 0.6,
    start_open: true,
    is_locked: true,
  });

});
