var $ = require('jquery');
var jQuery = $;

$(function(){
    //サイト内共通回遊用パーツ 導入事例カルーセル
    $('.case_carousel').slick({
        autoplay: true,
        autoplaySpeed: 4000,
        arrows: true,
        dots: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: true,
        centerPadding: 0,
        variableWidth: true,
        prevArrow: '<span class="slider_arrow prev_arrow"><img src="/assets/site_fronts/common/ico_product_arrow.svg" alt=""></span>',
        nextArrow: '<span class="slider_arrow next_arrow"><img src="/assets/site_fronts/common/ico_product_arrow.svg" alt=""></span>'
    });
});
