var $ = require('jquery');
var jQuery = $;

// バーガーメニューオープン／クローズ
$(window).on("turbo:load load resize",function(){
    if(window.matchMedia("(max-width:1023px)").matches){
        //スマホ用の処理
        $('.menu_open').on('click',function(){
            $('.sp_menu_container').fadeIn();
            $('body').addClass('bmenu_open');
        });

        $('.menu_close').on('click',function(){
            $('.sp_menu_container').fadeOut();
            $('body').removeClass('bmenu_open');
        });
    }else{
        //PC用の処理
        $('.menu_open').on('click',function(){
            $('.pc_menu_container').fadeIn();
            $('body').addClass('bmenu_open');
        });

        $('.menu_close').on('click',function(){
            $('.pc_menu_container').fadeOut();
            $('body').removeClass('bmenu_open');
        });
    }
});

// スクロール時のヘッダーの出現処理
var r = 1;
$(window).on("scroll", (function() {
    $(this).scrollTop() < r ? ($("body").removeClass("is_scroll_down").addClass("is_scroll_up"),
    $(this).scrollTop() < 80 && $("body").removeClass("is_scroll_up")) : $("body").removeClass("is_scroll_up").addClass("is_scroll_down"),
    r = $(this).scrollTop();

    if ($("body").hasClass('is_scroll_down')) {
        $('.header_top_link .drop_menu').removeClass("is-active");
        $('.header_top_link .h_menu_column').fadeOut();
    }
}));

// パンくずリストの横幅調整
document.addEventListener('turbo:load', () => {
  var window_size = $(window).width();

  if(window.matchMedia("(max-width:1023px)").matches){
      //スマホ用の処理
  }else{
      //PC用の処理
      var padding = (window_size - 1208) / 2
      $('.pankuz ul').css('padding-left', padding);
  }
});

//事例カルーセル用カードの高さ揃え
$(window).on("load resize",function(){
    var hList=new Array();
    $('.case_carousel_card').each(function(idx){
        //高さを追加
        hList[idx]=$(this).height();
    });
    var maxH=Math.max.apply(null,hList);
    $('.case_carousel_card').css({"height":maxH+80+"px"});
});
//事例カルーセル用カードの上下揃え
$(window).on("load resize",function(){
    var hList=new Array();
    $('.case_carousel_card .title').each(function(idx){
        //高さを追加
        hList[idx]=$(this).height();
    });
    var maxH=Math.max.apply(null,hList);
    $('.case_carousel_card .title').css({"height":maxH+"px"});
});


$(function() {
    //スムース移動
    $('a.smooth-movement[href^="#"]').click(function() {
        var speed = 400;
        var href= $(this).attr("href");
        var target = $(href == "#" || href == "" ? 'html' : href);
        var position = target.offset().top;
        $('body,html').animate({scrollTop:position}, speed, 'swing');
        return false;
    });

    //ヘッダーメニュー「製品」クリック時の処理
    $('nav .product').on('click',function(){
        $('.product_menu_container').fadeIn();
    });
    $('.product_menu_close').on('click',function(){
        $('.product_menu_container').fadeOut();
    });


     //ヘッダーメニュー クリック時の処理 （サブメニュー展開）
     $('.header_top_link .drop_menu').on('click',function(){
        if ($('.header_top_link .drop_menu').hasClass('is-active')) {
            $('.header_top_link .drop_menu').removeClass("is-active");
            $('.header_top_link .h_menu_column').fadeOut();
        }
        else {
            $('.header_top_link .drop_menu').addClass("is-active");
            $('.header_top_link .h_menu_column').fadeIn();
          }
    });

    //無効ボタンのリンク非活性化
    $('.wp-block-button.disabled').css('pointer-events', 'none').attr('tabindex', -1);

    //ページネーションのカレントリンク非活性化
    $('.pagenation_container li.current a').css('pointer-events', 'none').attr('tabindex', -1);

    //タブメニュー
    $('.tab_header .tab').on('click', function () {
        var index = $('.tab_header .tab').index(this);
        $('.tab_header .tab').removeClass('current');
        $(this).addClass('current');
        $('.tab_contents_box').hide().eq(index).fadeIn();
    });

    //アコーディオン
    $('.js-accordion').on('click', function () {
		$(this).next('dd').slideToggle();
        $(this).toggleClass('open');
	});

    //モーダルウィンドウ
    $(".modal_open").modaal({
        overlay_opacity: 0.6,
        background_scroll: false,
    });

    //ページトップの出現処理
    $('.pagetop').hide();
    $(window).on('scroll', ()=>{
        if( $(window).scrollTop() > 500 ){
            $('.pagetop').fadeIn();
        }else{
            $('.pagetop').fadeOut();
        }
    });

    //ABテストLP_検証1回目 ヘッダー
    $('body').on('scroll', ()=>{
        if( $('body').scrollTop() > 300 ){
            $('.site_header .main_header').addClass(' main_header--active')
        } else {
            $('.site_header .main_header').removeClass('main_header--active')
        };
    });

    //コラム一覧・詳細の資料ダウンロードバナー出現処理
    $('.floating_bnr').hide();
    $('.cta_block_container .close').on('click', function () {
        $('.floating_bnr').addClass('closed');
        $('.floating_bnr').fadeOut();
	});
    $(window).on('scroll', ()=>{
        if( $(window).scrollTop() > 800 ){
            if (!$('.floating_bnr').hasClass('closed')) {
                $('.floating_bnr').fadeIn();
            }
        }else{
            $('.floating_bnr').fadeOut();
        }
    });
});


//フォーム規約のアクティブ化　スクロール版
$(function() {
    $('#terms').bind('scroll', function() {
        //全長を取得
        scrollHeight = $('#terms').get(0).scrollHeight;
        //スクロールした高さを取得
        scrollPosition = $('#terms').height() + $('#terms').scrollTop();
        //スクロールの位置が下部何%の範囲に来た場合の処理(例:3%)
        if ((scrollHeight - scrollPosition) / scrollHeight <= 0.03) {
            $('#confirm-register').removeAttr('disabled');
            $('#confirm-register').parent().addClass("is-active")
            $('#confirm-register').parent().parent().addClass("is-active")
        }
    });

    $('#confirm-register').on('click', function () {
        $('.btn_confirm').removeClass("non-active")
	});
  });


  $(function() {
    $('.agree_check').on('click', function () {
        const ag_check = document.getElementById("ag_check");
        if (ag_check.checked) { 
            $('.btn_confirm').removeClass("non-active")
          } else {
            $('.btn_confirm').addClass("non-active")
          }
	});
  });

  //フォーム規約のアクティブ化　チェックボックス版
  $(function() {
    const ag_check01 = document.getElementById("ag_check01");
    const ag_check02 = document.getElementById("ag_check02");

    $('.agree_checkbtn').on('click', function () {
        if (ag_check01.checked) { 
            if (ag_check02.checked) { 
                $('.btn_confirm').removeClass("non-active")
            }
            else {
                $('.btn_confirm').addClass("non-active")
              }
          } else {
            $('.btn_confirm').addClass("non-active")
          }
	});
  });

  //WisemanExpo2023_プレゼント追従フェードアウト
  $("#js_fadeout_trigger").on("click", function() {
    $("#js_fadeout").addClass("fadeout");
  });