import 'bootstrap/dist/js/bootstrap';

var $ = require('jquery');

import '@/site_fronts/slick.js';
import '@/site_fronts/modaal.js';
import '@/site_fronts/cmn.js';
import '@/site_fronts/slider_setting.js';

import '@/modules/modal';
